import admin from './module/admin'
import user from './module/user'
import train from './module/train'
import link from './module/link'
import course from './module/course'
import vip from './module/vip'
import book from './module/book'
import article from './module/article'
import order from './module/order'

export const privateRoutes = [
  admin,
  user,
  article,
  train,
  link,
  course,
  vip,
  book,
  order
]
