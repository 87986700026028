import layout from '@/layout'
export default {
  path: '/order',
  component: layout,
  name: 'order',
  meta: {
    title: '订单',
    icon: 'order'
  },
  redirect: '/order/list',
  children: [
    {
      path: '/order/list',
      component: () => import('@/views/order/index'),
      name: 'order-list',
      meta: {
        title: '订单列表',
        icon: 'order'
      }
    }
  ]
}
